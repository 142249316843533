export class LocalStorage {
  static has(key: string): boolean {
    if (typeof window !== "undefined") {
      const value = window.localStorage.getItem(key)
      if (!!value) {
        return true
      }
    }
    return false
  }

  static get(key: string): any {
    if (typeof window !== "undefined") {
      return window.localStorage.getItem(key)
    }
  }

  static set(key: string, value: any): boolean {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(key, value)
      return true
    }
    return false
  }

  static remove(key: string): boolean {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(key)
      return true
    }
    return false
  }
}
