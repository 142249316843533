import { AxiosError, AxiosInstance, AxiosResponse } from "axios"
import { LocalStorage } from "./local_storage"

import {
  CreateAccountResponse,
  CreateInterviewRequest,
  GetInterviewResponse,
  GetInterviewsResponse,
} from "./api_types"

const API_TOKEN = "API_TOKEN"
const AUTHORIZATION = "Authorization"

export enum ErrorMessages {
  UNAUTHENTICATED = "UNAUTHENTICATED",
  UNAUTHORIZED = "UNAUTHORIZED",
  INVALID_EMAIL = "INVALID_EMAIL",
  ACCOUNT_ALREADY_EXISTS = "ACCOUNT_ALREADY_EXISTS",
  INTERVIEW_DOES_NOT_EXIST = "INTERVIEW_DOES_NOT_EXIST",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export interface ApiError<T> {
  errorMessage: ErrorMessages
  errorData: T
}

export interface ApiResponse<ResponseType, ErrorType> {
  data: ResponseType | undefined
  error: ApiError<ErrorType> | undefined
}

export class Api {
  client: AxiosInstance
  token: string | undefined

  constructor(client: AxiosInstance, apiUrl: string) {
    this.client = client
    this.client.defaults.baseURL = apiUrl
    this.client.defaults.headers.post["Access-Contrl-Allow-Origin"] = "*"
    if (LocalStorage.has(API_TOKEN)) {
      this.token = LocalStorage.get(API_TOKEN)
      this.client.defaults.headers.common[AUTHORIZATION] = this.token
    }
  }

  setApiToken(token: string) {
    this.token = token
    this.client.defaults.headers.common[AUTHORIZATION] = token
    LocalStorage.set(API_TOKEN, token)
  }

  async createAccount(
    email: string
  ): Promise<ApiResponse<CreateAccountResponse, undefined>> {
    try {
      const res: AxiosResponse<CreateAccountResponse> = await this.client
        .post("/account", {
          email: email,
        })
        .catch((err: AxiosError) => {
          if (err?.response?.status === 400) {
            throw new Error(ErrorMessages.INVALID_EMAIL)
          }
          if (err?.response?.status === 403) {
            throw new Error(ErrorMessages.ACCOUNT_ALREADY_EXISTS)
          }

          throw new Error(ErrorMessages.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: {
          errorMessage: e.message,
          errorData: undefined,
        },
      }
    }
  }

  async getInterviews(): Promise<
    ApiResponse<GetInterviewsResponse, undefined>
  > {
    try {
      const res: AxiosResponse<GetInterviewsResponse> = await this.client
        .get("/interview")
        .catch((err: AxiosError) => {
          if (err?.response?.status === 401) {
            throw new Error(ErrorMessages.UNAUTHORIZED)
          }

          throw new Error(ErrorMessages.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: {
          errorMessage: e.message,
          errorData: undefined,
        },
      }
    }
  }

  async getInterview(
    interviewId: string
  ): Promise<ApiResponse<GetInterviewResponse, undefined>> {
    try {
      const res: AxiosResponse<GetInterviewResponse> = await this.client
        .get(`/interview/${interviewId}`)
        .catch((err: AxiosError) => {
          if (err?.response?.status === 401) {
            throw new Error(ErrorMessages.UNAUTHORIZED)
          }

          throw new Error(ErrorMessages.UNKNOWN_ERROR)
        })

      if (!res.data.interview) {
        throw new Error(ErrorMessages.INTERVIEW_DOES_NOT_EXIST)
      }

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: {
          errorMessage: e.message,
          errorData: undefined,
        },
      }
    }
  }

  async createInterview(
    createInterviewRequest: CreateInterviewRequest
  ): Promise<ApiResponse<CreateAccountResponse, undefined>> {
    try {
      const res: AxiosResponse<CreateAccountResponse> = await this.client
        .post("/account/create", createInterviewRequest)
        .catch((err: AxiosError) => {
          if (err?.response?.status === 400) {
            throw new Error(ErrorMessages.INVALID_EMAIL)
          }
          if (err?.response?.status === 403) {
            throw new Error(ErrorMessages.ACCOUNT_ALREADY_EXISTS)
          }

          throw new Error(ErrorMessages.UNKNOWN_ERROR)
        })

      return {
        data: res.data,
        error: undefined,
      }
    } catch (e) {
      return {
        data: undefined,
        error: {
          errorMessage: e.message,
          errorData: undefined,
        },
      }
    }
  }
}
