import React, { createContext } from "react"
import axios from "axios"

import { Api } from "../../services/api"

const initialApiClient: Api = new Api(axios, `${process.env.GATSBY_API_SECURITY}${process.env.GATSBY_API_HOST}`)

export const ApiContext: React.Context<Api> = createContext(initialApiClient)

export const ApplicationContext: React.FC = ({ children }) => {
  return (
    <ApiContext.Provider value={initialApiClient}>
      {children}
    </ApiContext.Provider>
  )
}
